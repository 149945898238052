import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import "../dev-styles.scss";
import { ButtonContainer } from "../../../components/mdx/mdx-button-container/index.tsx";
import { SkillStack, addColorToSkills } from "../../../components/skillStack/index.tsx";
export const buttons = [{
  text: "pitch deck",
  url: "https://docs.google.com/presentation/d/e/2PACX-1vQzA30fyf4eUEYaeo62NKSjCKB16VFf-utFAUffsLBXiwkbCyqu89DUTJTaXM9sMcAOFrAIjelVDfBw/pub?delayms=3000&loop=false&start=false"
}, {
  text: "git repo",
  url: "https://github.com/four-loops/adVocation"
}];
export const tags = ["React", "React Router", "Javascript", "Node", "AWS", "EC2", "PM2", "Postgres", "Express", "Bootstrap"];
export const _frontmatter = {};
const layoutProps = {
  buttons,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className='mdx-default-wrapper'>
      <h1>{`AdVocation`}</h1>
      <p>{`Developed at a diversity hackathon, AdVocation is platform that allows under-represented candidates looking for referrals to find employees looking to refer candidates to their companies. The app features:`}</p>
      <ul>
        <li parentName="ul">{`A portal for a company’s employees to find applicants in the general public looking for referrals, including a shortlist functionality for sorting through candidates.`}</li>
        <li parentName="ul">{`The ability for employees at a company to post jobs.`}</li>
        <li parentName="ul">{`A portal for job seekers to create profiles and look for open jobs where a company’s employees are looking to refer.`}</li>
      </ul>
      <ButtonContainer buttons={buttons} mdxType="ButtonContainer" />
      <SkillStack skills={addColorToSkills(tags)} mdxType="SkillStack" />
      <img className="mdx-default-media" src="https://images.squarespace-cdn.com/content/v1/54c0768de4b07740895fb25f/1572451023341-RVN7VWFQU58M347GQXPE/ke17ZwdGBToddI8pDm48kD33KhhWEodMJvcytjXFyvFZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZamWLI2zvYWH8K3-s_4yszcp2ryTI0HqTOaaUohrI8PIQVUjsvMYGrjk5P5guv3Gb1aPQrnDLhtGUJ-UJkarKCw/adVocation.png?format=1500w" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      